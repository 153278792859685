
import { defineComponent } from 'vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import { OrdersListInterface } from '@/shared/interfaces/orders-list.interface'
import moment from 'moment'

export default defineComponent({
  name: 'Order',
  props: {
    order: {
      type: Object as () => OrdersListInterface,
      required: true
    }
  },
  data () {
    return {
      show: false,
      moment,
      timeLabel: '',
      timeCalculation: null
    }
  },
  methods: {
    toggleCollapse (): void {
      this.show = !this.show
    },
    editOrder (orderId: number, orderStringId: string, statusName: string): void {
      this.$emit('editOrder', { orderId, orderStringId, statusName })
    },
    getOrderStatusTime () {
      const status = this.order.orderStatus.name as string
      if (Array.isArray(this.order.orderStatusTime)) {
        const statusObject = this.order.orderStatusTime.filter((s) => s.status === status)
        if (statusObject.length && statusObject[0].value !== null) {
          return statusObject[0].value
        }
      }
      return this.order.createdAt
    },
    setOrderStatusTimeLabel () {
      const status = this.order.orderStatus.name as string
      if (['canceled', 'returned', 'delivered'].includes(status)) {
        this.timeLabel = moment(this.getOrderStatusTime()).format('hh:mm A')
        return false
      }
      const now = moment()
      const statusTime = this.getOrderStatusTime()
      const duration = moment.duration(now.diff(statusTime)) as any
      const days = parseInt(duration.asDays())
      // duration in hours
      const hours = parseInt(duration.asHours()) % 24
      // duration in minutes
      const minutes = parseInt(duration.asMinutes()) % 60
      this.timeLabel = moment(statusTime).format('hh:mm A') + '<small> ('
      if (days > 0) {
        this.timeLabel += days + 'd '
      }
      if (hours > 0) {
        this.timeLabel += hours + 'h '
      }
      if (minutes > 0) {
        this.timeLabel += minutes + 'min'
      }
      this.timeLabel += ')</small>'
      if (!days && !hours && !minutes) {
        this.timeLabel = moment(statusTime).format('hh:mm A') + '<small> (now)</small>'
      }
    }
  },
  components: {
    BaseIcon
  },
  beforeUnmount () {
    clearInterval(this.timeCalculation as any)
  },
  created () {
    this.setOrderStatusTimeLabel()
    this.timeCalculation = setInterval(() => this.setOrderStatusTimeLabel(), 60000) as any
  },
  watch: {
    order: {
      handler () {
        this.setOrderStatusTimeLabel()
      },
      deep: true
    }
  },
  computed: {
    isPrimeOrder () {
      return this.order?.priority ?? false
    }
  }
})
